// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-withdrawals-ok-success_YeRzz{justify-content:center}@media(min-height:520px){.payments-withdrawals-ok-success-wrapper_WQ1II{min-height:520px}}html.app__layout--desktop .payments-withdrawals-ok-success_YeRzz{height:540px;padding-bottom:60px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-withdrawals-ok-success": `payments-withdrawals-ok-success_YeRzz`,
	"payments-withdrawals-ok-success-wrapper": `payments-withdrawals-ok-success-wrapper_WQ1II`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-deposits_jBmCJ{display:flex;flex:1;background-color:var(--Layer0)}html.app__layout--desktop .payments-deposits--ekyc_XWTgR{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:flex;gap:12px;align-items:center;padding:12px;margin-top:8px;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .payments-deposits_jBmCJ .deposit-bonus-selector_uQ8yc{margin-top:0}html.app__layout--desktop .payments-deposits--bonuses_YQcZt{width:100%}html.app__layout--desktop .payments-deposits--loading_MbEeD{align-items:center;justify-content:center;min-height:560px}html.app__layout--desktop .payments-deposits--full-height_S_lar{height:100%;margin:auto 0}html.app__layout--desktop .payments-deposits__inner_guBZt{flex:1;width:100%;padding:0 24px}html.app__layout--desktop .payments-deposits__no-payments_sPIQF{margin:16px 8px 0}html.app__layout--desktop .payments-deposits__withdrawal-button_J73b_{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;height:44px;margin:8px 0 32px;color:var(--TextPrimary);text-align:center;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}html.app__layout--desktop .payments-deposits__withdrawal-button_J73b_:hover{color:var(--BrandHighlight)}html.app__layout--desktop .payments-deposits__bonus-container_ywRww{width:100%;padding:0 16px;width:375px;padding:16px 32px}html.app__layout--desktop :local__notification{position:relative;margin-right:8px;margin-left:8px;margin:16px 0 0}html.app__layout--desktop :local__notification-mask{z-index:1;position:absolute;top:0;right:0;bottom:0;left:0;display:block}html.app__layout--desktop :local__notification-text{display:block;margin-bottom:16px}html.app__layout--desktop :local__notification-text:last-child{margin-bottom:0}html.app__layout--desktop :local__notification-cursor-default{cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-deposits": `payments-deposits_jBmCJ`,
	"payments-deposits--ekyc": `payments-deposits--ekyc_XWTgR`,
	"deposit-bonus-selector": `deposit-bonus-selector_uQ8yc`,
	"payments-deposits--bonuses": `payments-deposits--bonuses_YQcZt`,
	"payments-deposits--loading": `payments-deposits--loading_MbEeD`,
	"payments-deposits--full-height": `payments-deposits--full-height_S_lar`,
	"payments-deposits__inner": `payments-deposits__inner_guBZt`,
	"payments-deposits__no-payments": `payments-deposits__no-payments_sPIQF`,
	"payments-deposits__withdrawal-button": `payments-deposits__withdrawal-button_J73b_`,
	"payments-deposits__bonus-container": `payments-deposits__bonus-container_ywRww`
};
export default ___CSS_LOADER_EXPORT___;

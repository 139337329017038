// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-crypto-currency-select_Q8xp3{display:flex;flex-direction:column;align-items:center;width:100%;padding:0}.payments-crypto-currency-select__row_Dlr0G{flex:none;width:100%;padding-bottom:16px;-webkit-user-select:none;user-select:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-crypto-currency-select": `payments-crypto-currency-select_Q8xp3`,
	"payments-crypto-currency-select__row": `payments-crypto-currency-select__row_Dlr0G`
};
export default ___CSS_LOADER_EXPORT___;

import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { ref } from 'vue';
import { PaymentType } from '@leon-hub/api-sdk';
import PaymentsCopyCryptoAddress from 'web/src/modules/payments/components/PaymentsCopyCryptoAddress/PaymentsCopyCryptoAddress.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SingleSelect from 'web/src/components/Select/components/SingleSelect.vue';
import PaymentsLogo from 'web/src/modules/payments/components/PaymentsLogo/PaymentsLogo.vue';
import { usePaymentsCrypto } from 'web/src/modules/payments/components/PaymentsCrypto/composables';
import DepositBonusSelector from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelector.vue';
import usePaymentsBonuses from 'web/src/modules/payments/composables/usePaymentsBonuses';
import PaymentsCryptoHideButton from 'web/src/modules/payments/components/PaymentsCrypto/components/PaymentsCryptoHideButton.vue';
import PaymentsCryptoCopyButton from 'web/src/modules/payments/components/PaymentsCrypto/components/PaymentsCryptoCopyButton.vue';
import SPaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/components/SPaymentBonuses/SPaymentBonuses.vue';
import SPaymentsCopyCryptoAddress from 'web/src/modules/payments/components/PaymentsCopyCryptoAddress/SPaymentsCopyCryptoAddress.vue';
import PaymentsCryptoCurrencySelect from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/PaymentsCryptoCurrencySelect.vue';
import { DropListSelect } from 'web/src/components/DropListSelect';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { ModalSelector } from 'web/src/modules/core/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCrypto',
    props: {
        paymentSystem: {},
        initialModalProperties: {}
    },
    setup (__props) {
        const props = __props;
        const { hasError, loading, isQRShown, hintBlockProps, paymentsLogoProps, cryptoNetwork, listOfMethodsButtonProps, imageQr, selectedNetwork, networkSelectOptions, networkDropdownOptions, cryptoDepositAddressLabel, cryptoCurrencyRateDescription, depositBonusList, minAmountFormatted, selectPaymentMethod, selectNetwork, onNativeNetworkSelect, handleCopy, handlePushToMainPaymentPage, onDepositBonusChange, handleHide } = usePaymentsCrypto(props);
        const { isNoneSelected, selectedBonus, isChoosingBonusBeforeDeposit } = usePaymentsBonuses();
        const modalSelector = ModalSelector.DESKTOP_INNER_MODAL;
        const hasInitiateModal = ref(null !== props.initialModalProperties);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass([
                    _ctx.$style['payments-crypto'],
                    "payments-crypto"
                ])
            }, [
                (_openBlock(), _createBlock(PaymentsLogo, _mergeProps({
                    key: 0
                }, _unref(paymentsLogoProps), {
                    class: "payments-crypto__logo"
                }), null, 16)),
                _createCommentVNode("", true),
                _unref(cryptoNetwork)?.networks && _unref(cryptoNetwork)?.networks.length > 1 ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['payments-crypto__row'])
                }, [
                    (_openBlock(), _createBlock(SingleSelect, {
                        key: 1,
                        label: _ctx.$t('WEB_2_CRYPTO_NETWORK'),
                        name: "network",
                        "selected-value": _unref(selectedNetwork),
                        options: _unref(networkSelectOptions),
                        onChange: _unref(onNativeNetworkSelect)
                    }, null, 8, [
                        "label",
                        "selected-value",
                        "options",
                        "onChange"
                    ]))
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['payments-crypto__content'])
                }, [
                    _unref(loading) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['payments-crypto__loading'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['payments-crypto__loading-text'])
                        }, _toDisplayString(_ctx.$t('WEB_2_CRYPTO_LOADING')), 3)
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['payments-crypto__inner']]: true,
                            [_ctx.$style['payments-crypto__inner-loading']]: _unref(loading) || _unref(hasError)
                        })
                    }, [
                        (_openBlock(), _createBlock(PaymentsCopyCryptoAddress, {
                            key: 1,
                            address: _unref(cryptoNetwork)?.current.address || '',
                            label: _unref(cryptoDepositAddressLabel),
                            onClick: _unref(handleCopy)
                        }, null, 8, [
                            "address",
                            "label",
                            "onClick"
                        ])),
                        _unref(isQRShown) ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass(_ctx.$style['payments-crypto__qr'])
                        }, [
                            _unref(cryptoNetwork)?.current.qrCodeImgSrc ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['payments-crypto__qr-image']),
                                src: _unref(imageQr),
                                alt: ""
                            }, null, 10, _hoisted_1)) : _createCommentVNode("", true)
                        ], 2)) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _unref(depositBonusList).length ? (_openBlock(), _createBlock(DepositBonusSelector, {
                    key: 3,
                    "deposit-bonus-list": _unref(depositBonusList),
                    "selected-bonus": _unref(selectedBonus),
                    "is-none-selected": _unref(isNoneSelected),
                    "is-choosing-bonus-before-deposit": _unref(isChoosingBonusBeforeDeposit),
                    class: _normalizeClass(_ctx.$style['payments-crypto__bonuses']),
                    onChange: _unref(onDepositBonusChange)
                }, null, 8, [
                    "deposit-bonus-list",
                    "selected-bonus",
                    "is-none-selected",
                    "is-choosing-bonus-before-deposit",
                    "class",
                    "onChange"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['payments-crypto__buttons'])
                }, [
                    _unref(loading) || _unref(cryptoNetwork)?.current.qrCodeImgSrc ? (_openBlock(), _createBlock(PaymentsCryptoHideButton, {
                        key: 0,
                        "is-q-r-shown": _unref(isQRShown),
                        loading: _unref(loading),
                        "has-error": _unref(hasError),
                        class: _normalizeClass(_ctx.$style['payments-crypto__button']),
                        onClick: _unref(handleHide)
                    }, null, 8, [
                        "is-q-r-shown",
                        "loading",
                        "has-error",
                        "class",
                        "onClick"
                    ])) : _createCommentVNode("", true),
                    _createVNode(PaymentsCryptoCopyButton, _mergeProps(props, {
                        loading: _unref(loading),
                        "has-error": _unref(hasError),
                        class: _ctx.$style['payments-crypto__button'],
                        onClick: _unref(handleCopy)
                    }), null, 16, [
                        "loading",
                        "has-error",
                        "class",
                        "onClick"
                    ])
                ], 2),
                _createCommentVNode("", true),
                (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                    key: 5,
                    class: _ctx.$style['payments-crypto__hint']
                }, _unref(hintBlockProps)), {
                    title: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB_2_CRYPTO_CURRENCY_RATE_TITLE')), 1)
                        ]),
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['payments-crypto__hint-content'])
                            }, [
                                _createElementVNode("span", null, _toDisplayString(_unref(cryptoNetwork)?.current.currencyRate), 1),
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB_2_CRYPTO_CURRENCY_RATE_SUBTITLE')), 1)
                            ], 2)
                        ]),
                    _: 1
                }, 16, [
                    "class"
                ])),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['payments-crypto__description'])
                }, _toDisplayString(_unref(cryptoCurrencyRateDescription)), 3),
                (_openBlock(), _createBlock(VButton, _mergeProps({
                    key: 6
                }, _unref(listOfMethodsButtonProps), {
                    class: _ctx.$style['payments-crypto__other-methods'],
                    onClick: _unref(handlePushToMainPaymentPage)
                }), null, 16, [
                    "class",
                    "onClick"
                ])),
                (_unref(depositBonusList).length, _createCommentVNode("", true)),
                _createVNode(ModalPortal, {
                    selector: _unref(modalSelector)
                }, {
                    default: _withCtx(()=>[
                            hasInitiateModal.value && _ctx.initialModalProperties ? (_openBlock(), _createBlock(ConfirmModal, {
                                key: 0,
                                modal: _ctx.initialModalProperties,
                                onClose: _cache[0] || (_cache[0] = ($event)=>hasInitiateModal.value = false),
                                onButtonClick: _cache[1] || (_cache[1] = ($event)=>hasInitiateModal.value = false)
                            }, null, 8, [
                                "modal"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsCrypto'
                ]
            ]);
        };
    }
});

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-withdrawal_vZazL{background-color:var(--Layer0);padding:0 24px}html.app__layout--desktop .payments-withdrawal--loading_Hax5T{display:flex;flex:1;align-items:center;justify-content:center;min-height:560px}html.app__layout--desktop .payments-withdrawal--full-height_RG5eQ{height:100%;margin:auto 0}html.app__layout--desktop .payments-withdrawal__no-payments_UTO2D{padding:16px 8px 0}html.app__layout--desktop .payments-withdrawal__identification-status_RiLkC{padding-right:8px;padding-left:8px}html.app__layout--desktop .payments-withdrawal__deposits-button_wFGJf{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;height:44px;margin:8px 0 32px;color:var(--TextPrimary);text-align:center;-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}html.app__layout--desktop .payments-withdrawal__deposits-button_wFGJf:hover{color:var(--BrandHighlight)}html.app__layout--desktop .payments-withdrawal__notification_oZepn{position:relative;margin-right:8px;margin-left:8px;margin:16px 0 0}html.app__layout--desktop .payments-withdrawal__notification-mask_lNdba{z-index:1;position:absolute;top:0;right:0;bottom:0;left:0;display:block}html.app__layout--desktop .payments-withdrawal__notification-text_nYcVH{display:block;margin-bottom:16px}html.app__layout--desktop .payments-withdrawal__notification-text_nYcVH:last-child{margin-bottom:0}html.app__layout--desktop .payments-withdrawal__notification-cursor-default_mxn8h{cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-withdrawal": `payments-withdrawal_vZazL`,
	"payments-withdrawal--loading": `payments-withdrawal--loading_Hax5T`,
	"payments-withdrawal--full-height": `payments-withdrawal--full-height_RG5eQ`,
	"payments-withdrawal__no-payments": `payments-withdrawal__no-payments_UTO2D`,
	"payments-withdrawal__identification-status": `payments-withdrawal__identification-status_RiLkC`,
	"payments-withdrawal__deposits-button": `payments-withdrawal__deposits-button_wFGJf`,
	"payments-withdrawal__notification": `payments-withdrawal__notification_oZepn`,
	"payments-withdrawal__notification-mask": `payments-withdrawal__notification-mask_lNdba`,
	"payments-withdrawal__notification-text": `payments-withdrawal__notification-text_nYcVH`,
	"payments-withdrawal__notification-cursor-default": `payments-withdrawal__notification-cursor-default_mxn8h`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-deposits-ok-process__Z0xS{justify-content:center}html.app__layout--desktop .payments-deposits-ok-process__Z0xS{height:540px;padding-bottom:60px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-deposits-ok-process": `payments-deposits-ok-process__Z0xS`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-top-bar__content_BoOYX{display:inline-flex;flex-direction:column;flex-grow:1;align-items:center;justify-content:center;padding-top:16px;text-align:center}.payments-top-bar__content-title_KU_1p{font-size:18px;font-weight:600;line-height:24px;color:var(--ColorContent1)}.payments-top-bar__content-subtitle_Z6HeA{font-size:11px;font-weight:400;line-height:16px;color:var(--ColorContent1a)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-top-bar__content": `payments-top-bar__content_BoOYX`,
	"payments-top-bar__content-title": `payments-top-bar__content-title_KU_1p`,
	"payments-top-bar__content-subtitle": `payments-top-bar__content-subtitle_Z6HeA`
};
export default ___CSS_LOADER_EXPORT___;

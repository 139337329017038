// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-withdrawals-details-page_UeV0Y{padding:16px 0 32px;min-height:340px}html.app__layout--desktop .payments-withdrawals-details-page--extra-height_tTwdU{padding-bottom:46px}html.app__layout--desktop .payments-withdrawals-details-page--loading_Buu7D{display:flex;align-items:center;min-height:188px}html.app__layout--desktop .payments-withdrawals-details-page__row_TIBop{padding:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-withdrawals-details-page": `payments-withdrawals-details-page_UeV0Y`,
	"payments-withdrawals-details-page--extra-height": `payments-withdrawals-details-page--extra-height_tTwdU`,
	"payments-withdrawals-details-page--loading": `payments-withdrawals-details-page--loading_Buu7D`,
	"payments-withdrawals-details-page__row": `payments-withdrawals-details-page__row_TIBop`
};
export default ___CSS_LOADER_EXPORT___;

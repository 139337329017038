// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-deposits-details-page_sbU7y{padding:16px 0 32px}html.app__layout--desktop .payments-deposits-details-page--has-redirect_P2m9l{display:flex;flex:1;padding-bottom:0}html.app__layout--desktop .payments-deposits-details-page--loading_BQRpM{display:flex;align-items:center;min-height:188px}html.app__layout--desktop .payments-deposits-details-page--has-redirect_P2m9l{padding:16px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-deposits-details-page": `payments-deposits-details-page_sbU7y`,
	"payments-deposits-details-page--has-redirect": `payments-deposits-details-page--has-redirect_P2m9l`,
	"payments-deposits-details-page--loading": `payments-deposits-details-page--loading_BQRpM`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restriction-modal__list-item_lDnsi{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault);background-color:var(--Layer2)}.restriction-modal__list-item_lDnsi.active_KNZol{color:var(--BrandDefault)}.restriction-modal__label_TGfYY{display:block;margin:0 0 8px}.restriction-modal__label_TGfYY a{font-weight:400;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none}.restriction-modal__label_TGfYY strong{font-weight:bolder;color:var(--TextPrimary)}.restriction-modal__list_ZUp7g{display:flex;flex-direction:column;gap:2px}.restriction-modal__item_sUqph{display:flex;align-items:center;justify-content:space-between;width:100%;height:44px;padding:0 12px;background-color:var(--Layer2)}.restriction-modal__item_sUqph:first-child{border-radius:5px 5px 0 0}.restriction-modal__item_sUqph:last-child{border-radius:0 0 5px 5px}.restriction-modal__title_Iz3Mt{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}.restriction-modal__value_v1Fsm{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextDefault)}.restriction-modal__value--active_eAnih{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restriction-modal__list-item": `restriction-modal__list-item_lDnsi`,
	"active": `active_KNZol`,
	"restriction-modal__label": `restriction-modal__label_TGfYY`,
	"restriction-modal__list": `restriction-modal__list_ZUp7g`,
	"restriction-modal__item": `restriction-modal__item_sUqph`,
	"restriction-modal__title": `restriction-modal__title_Iz3Mt`,
	"restriction-modal__value": `restriction-modal__value_v1Fsm`,
	"restriction-modal__value--active": `restriction-modal__value--active_eAnih`
};
export default ___CSS_LOADER_EXPORT___;

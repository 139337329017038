import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import usePaymentsCryptoCurrencySelect from 'web/src/modules/payments/components/PaymentsCryptoCurrencySelect/composables/usePaymentsCryptoCurrencySelect';
import { DropListSelect } from 'web/src/components/DropListSelect';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsCryptoCurrencySelect',
    props: {
        type: {},
        paymentSystem: {}
    },
    emits: [
        "on-select"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { selectedCurrency, currencyDropdownOptions, selectPaymentMethod } = usePaymentsCryptoCurrencySelect(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['payments-crypto-currency-select'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['payments-crypto-currency-select__row'])
                }, [
                    _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsCryptoCurrencySelect'
                ]
            ]);
        };
    }
});

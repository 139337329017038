import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import PaymentsList from 'web/src/modules/payments/components/PaymentsList/PaymentsList.vue';
import useDepositsRoutePage from 'web/src/modules/payments/submodules/deposits/composables/useDepositsRoutePage';
import DepositBonusSelector from 'web/src/modules/payments/form-widgets/DepositBonusSelector/components/DepositBonusSelector.vue';
import PaymentBonuses from 'web/src/modules/payments/components/PaymentBonuses/PaymentBonuses.vue';
import usePaymentsBonuses from 'web/src/modules/payments/composables/usePaymentsBonuses';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PaymentsIdentificationStatus from 'web/src/modules/payments/components/PaymentsIdentificationStatus/PaymentsIdentificationStatus.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentsDepositsRoutePage',
    setup (__props) {
        const { lastDepositedId, loading, showIdentificationStatus, depositCategories, isPaymentBonuses, isPaymentBonusesBeforeDeposit, goToWithdrawals, goToDepositsDetailsRoute, paymentBonuses, identificationStatusProperties, onIdentificationStatusButtonClick, hasErrorCode, hintErrorProperties, sHintErrorProperties, onErrorModalAction, titleErrorModal, errorMessage, isEkycVerificationShown, verifyEkyc } = useDepositsRoutePage();
        const { depositBonusList, isNoneSelected, selectedBonus, isChoosingBonusBeforeDeposit } = usePaymentsBonuses();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-deposits']]: true,
                    [_ctx.$style['payments-deposits--loading']]: _unref(loading),
                    [_ctx.$style['payments-deposits--full-height']]: _unref(showIdentificationStatus),
                    [_ctx.$style['payments-deposits--bonuses']]: _unref(isChoosingBonusBeforeDeposit)
                })
            }, [
                _unref(loading) || _unref(lastDepositedId) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : 0 === _unref(depositCategories).length ? (_openBlock(), _createBlock(VJumbotron, {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['payments-deposits__no-payments']),
                    text: _unref(errorMessage) || _ctx.$t('WEB2_NO_PAYMENTS_SYSTEMS_ERROR')
                }, null, 8, [
                    "class",
                    "text"
                ])) : _unref(isPaymentBonusesBeforeDeposit) ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(_ctx.$style['payments-deposits__bonus-container'])
                }, [
                    _createVNode(PaymentBonuses, {
                        "bonus-list": _unref(paymentBonuses)
                    }, null, 8, [
                        "bonus-list"
                    ])
                ], 2)) : (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: _normalizeClass(_ctx.$style['payments-deposits__inner'])
                }, [
                    _unref(hasErrorCode) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        (_openBlock(), _createBlock(VHintBlock, _mergeProps({
                            key: 1
                        }, _unref(hintErrorProperties), {
                            class: _ctx.$style['payments-deposits__notification'],
                            onOnAccept: _unref(onErrorModalAction)
                        }), {
                            title: _withCtx(()=>[
                                    _createElementVNode("span", null, _toDisplayString(_unref(titleErrorModal)), 1)
                                ]),
                            default: _withCtx(()=>[
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['payments-deposits__notification-text'])
                                    }, _toDisplayString(_unref(errorMessage) ?? ''), 3)
                                ]),
                            _: 1
                        }, 16, [
                            "class",
                            "onOnAccept"
                        ]))
                    ], 64)) : _createCommentVNode("", true),
                    _unref(isPaymentBonuses) ? (_openBlock(), _createBlock(DepositBonusSelector, {
                        key: 1,
                        "deposit-bonus-list": _unref(depositBonusList),
                        "selected-bonus": _unref(selectedBonus),
                        "is-choosing-bonus-before-deposit": _unref(isChoosingBonusBeforeDeposit),
                        "is-none-selected": _unref(isNoneSelected)
                    }, null, 8, [
                        "deposit-bonus-list",
                        "selected-bonus",
                        "is-choosing-bonus-before-deposit",
                        "is-none-selected"
                    ])) : _createCommentVNode("", true),
                    _unref(isEkycVerificationShown) ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            [_ctx.$style['payments-deposits--ekyc']]: true
                        })
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MORE_PAYMENTS')) + " ", 1),
                        _createVNode(VButton, {
                            height: _unref(ButtonHeight).SMALL,
                            kind: _unref(ButtonKind).CANCEL,
                            label: _ctx.$t('WEB2_VERIFY'),
                            onClick: _unref(verifyEkyc)
                        }, null, 8, [
                            "height",
                            "kind",
                            "label",
                            "onClick"
                        ])
                    ], 2)) : _createCommentVNode("", true),
                    _createVNode(PaymentsList, {
                        categories: _unref(depositCategories),
                        onClick: _unref(goToDepositsDetailsRoute)
                    }, null, 8, [
                        "categories",
                        "onClick"
                    ]),
                    (_openBlock(), _createElementBlock("span", {
                        key: 3,
                        class: _normalizeClass(_ctx.$style['payments-deposits__withdrawal-button']),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(goToWithdrawals) && _unref(goToWithdrawals)(...args);
                        })
                    }, _toDisplayString(_ctx.$t('WEB2_BANK_TRANSFER')), 3))
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentsDepositsRoutePage'
                ]
            ]);
        };
    }
});

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-crypto_oRI37{display:flex;flex-direction:column;align-items:center;width:100%;padding:0}.payments-crypto__row_kfgPo{flex:none;width:100%;padding-bottom:0;-webkit-user-select:none;user-select:none}.payments-crypto__bonuses_t5ntq{flex:1;width:100%;margin:16px 0 0}.payments-crypto__text_CvgLw{flex:1;width:100%;padding:12px;margin-top:16px;text-align:center;background:none;border:1px none sold;border-radius:12px}.payments-crypto__text_CvgLw,.payments-crypto__text_CvgLw span{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:none}.payments-crypto__qr_DF8By{width:144px;height:144px;margin:4px 0 0;overflow:hidden;background-color:var(--LLayer1);border-radius:5px}.payments-crypto__qr-image_DrGw4{width:100%;height:100%;object-fit:cover}.payments-crypto__buttons_ItoEG{display:flex;gap:16px;width:100%}.payments-crypto__button_RkGQE{margin-top:18px}.payments-crypto__hint_Btwla{width:100%;margin-top:20px}.payments-crypto__hint-content_ZN3IO{display:inline-flex;flex-direction:column}.payments-crypto__description__oYhS{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;margin-top:16px;color:var(--TextPrimary);text-align:left}.payments-crypto__content_HA84v,.payments-crypto__inner_Yd2km{position:relative;display:flex;flex-direction:column;align-items:center;width:100%}.payments-crypto__content-loading_TJ5lu,.payments-crypto__inner-loading_WeIWn{pointer-events:none;opacity:.3}.payments-crypto__inner_Yd2km{row-gap:0}.payments-crypto__other-methods_ZOQZI{margin:46px 0 20px}.payments-crypto__loading_tsgzT{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;display:flex;align-items:center;justify-content:center}.payments-crypto__loading-text_ugZLS{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;padding:16px;color:var(--LTextDefault);background-color:var(--LLayer1);border-radius:8px}html.app__layout--desktop .payments-crypto_oRI37{padding:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-crypto": `payments-crypto_oRI37`,
	"payments-crypto__row": `payments-crypto__row_kfgPo`,
	"payments-crypto__bonuses": `payments-crypto__bonuses_t5ntq`,
	"payments-crypto__text": `payments-crypto__text_CvgLw`,
	"payments-crypto__qr": `payments-crypto__qr_DF8By`,
	"payments-crypto__qr-image": `payments-crypto__qr-image_DrGw4`,
	"payments-crypto__buttons": `payments-crypto__buttons_ItoEG`,
	"payments-crypto__button": `payments-crypto__button_RkGQE`,
	"payments-crypto__hint": `payments-crypto__hint_Btwla`,
	"payments-crypto__hint-content": `payments-crypto__hint-content_ZN3IO`,
	"payments-crypto__description": `payments-crypto__description__oYhS`,
	"payments-crypto__content": `payments-crypto__content_HA84v`,
	"payments-crypto__inner": `payments-crypto__inner_Yd2km`,
	"payments-crypto__content-loading": `payments-crypto__content-loading_TJ5lu`,
	"payments-crypto__inner-loading": `payments-crypto__inner-loading_WeIWn`,
	"payments-crypto__other-methods": `payments-crypto__other-methods_ZOQZI`,
	"payments-crypto__loading": `payments-crypto__loading_tsgzT`,
	"payments-crypto__loading-text": `payments-crypto__loading-text_ugZLS`
};
export default ___CSS_LOADER_EXPORT___;
